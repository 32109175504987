body
{
    font-size: .85em;
    font-family: "Trebuchet MS", Verdana, Helvetica, Sans-Serif;
    color: #FFFFFF;
    background-color: #333333;
}

header,
footer,
nav,
section {
    display: block;
}

#header {
    text-align: center;
}
#header h3 {
    font-size: 50px;
    font-weight: bold;
}
#header h3 a {
    color: #ffffff;
    text-decoration: none;
}

#footer {
    color: #999999;
    padding: 50px;
    text-align: center;
}
#container {
    width: 100%;    
}

#menu-container {
    width: 100%;
}

#menu {
    margin: auto;
    width: 440px;
    height: 50px;
    text-align: left;
}

#menu ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

#menu ol li {
    float: left;
}

#menu ol li a 
{
    display: block;
    width: 108px;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    padding: 8px 0;
    border: #ffffff 1px solid;
}

#menu ol li a:hover {
    background: #666666;
    color: #990000;
}

.current-menu-link {
    color: #990000!important;
}

/* Styles for basic forms
-----------------------------------------------------------*/

fieldset 
{
    border:1px solid #ddd;
    padding:0 1.4em 1.4em 1.4em;
    margin:0 0 1.5em 0;
}

legend 
{
    font-size:1.2em;
    font-weight: bold;
}

textarea 
{
    min-height: 75px;
}

.editor-label 
{
    margin: 1em 0 0 0;
}

.editor-field 
{
    margin:0.5em 0 0 0;
}


/* Styles for validation helpers
-----------------------------------------------------------*/
.field-validation-error
{
    color: #ff0000;
}

.field-validation-valid
{
    display: none;
}

.input-validation-error
{
    border: 1px solid #ff0000;
    background-color: #ffeeee;
}

.validation-summary-errors
{
    font-weight: bold;
    color: #ff0000;
}

.validation-summary-valid
{
    display: none;
}

